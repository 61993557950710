@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@300;400&family=Roboto:wght@400;500&display=swap");
@font-face {
    font-family: "AIR";
    src: local("AIR"),
     url("../Assets/font/AirPro.ttf") format("truetype");
    font-weight: heavy;
   }
   @font-face {
    font-family: "Hong";
    src: local("Hong"),
     url("../Assets/font/NewFont-Regular.ttf") format("truetype");
    font-weight: Regular;
   }
   @font-face {
    font-family: "PIX";
    src: local("PIX"),
     url("../Assets/font/N1MONO-MONO.ttf") format("truetype");
    font-weight: Regular;
   }
   

img {
    width: 100%;
    height: 100%;
    display: block;
}

body {
    margin: 0;
    padding: 0;
}

[class^="number-slide"],
[class*=" number-slide"] {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 300px;
    max-height: 100vh;
}

.blockThumbFull {
    background-size: contain;
  }

form {
    width: 90%;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-direction: row;
}  
form input {
    flex-grow: 5;
}

